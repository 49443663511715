import axios from '@/plugins/axios'
import { getAccountId } from '@/helper/auth-helper'

const getGroups = params => axios.get(`${getAccountId()}/target-groups/paginated`, { params })
const getAllGroups = () => axios.get(`${getAccountId()}/target-groups/all`)
const getDetailGroup = groupId => axios.get(`${getAccountId()}/target-groups/${groupId}`)
const getOverrideOptions = () => axios.get(`${getAccountId()}/target-groups/override-options`)
const resetGroupCaps = groupId => axios.delete(`${getAccountId()}/targets-groups/${groupId}/caps`)
const createGroup = formData => axios.post(`${getAccountId()}/target-groups`, formData)
const updateGroup = ({ groupId, formData }) => axios.patch(`${getAccountId()}/target-groups/${groupId}`, formData)
const attachDestinationsByGroup = ({ groupId, attach }) => axios.patch(`${getAccountId()}/target-groups/${groupId}/targets`, { attach })
const detachDestinationsFromGroup = ({ groupId, detach }) => axios.patch(`${getAccountId()}/target-groups/${groupId}/targets`, { detach })
const startDestinationsFromGroup = groupId => axios.patch(`${getAccountId()}/target-groups/${groupId}`, { pauseTargets: false })
const pauseDestinationsFromGroup = groupId => axios.patch(`${getAccountId()}/target-groups/${groupId}`, { pauseTargets: true })
const deleteGroup = groupId => axios.delete(`${getAccountId()}/target-groups/${groupId}`)

export {
  getGroups, getAllGroups, getDetailGroup, getOverrideOptions, resetGroupCaps, createGroup, updateGroup, attachDestinationsByGroup, detachDestinationsFromGroup, startDestinationsFromGroup, pauseDestinationsFromGroup, deleteGroup
}
<template>
  <v-container fluid class="pa-8 pa-sm-15">
    <div class="d-flex flex-wrap align-center justify-end">
      <portal to="pageTitle" :disabled="checkMd">
        <div class="font-page-header pr-5 pr-sm-15 mb-md-15">Manage Groups</div>
      </portal>
      
      <ActionOutlinedButton
        class="mr-5 mr-md-11 mb-4 mb-sm-15"
        :to="{ name: 'CreateGroup'}"
      >
        Create group
      </ActionOutlinedButton>
  
      <ActionOutlinedButton
        class="mr-5 mr-md-11 mb-4 mb-sm-15"
        :to="{ name: 'CreateDestination'}"
      >
        Create destination
      </ActionOutlinedButton>
      
      <RefreshButton class="mr-5 mr-md-11 mb-4 mb-sm-15" :disabled="loading" @click="refresh" />
      
      <v-spacer />
      
      <SearchField
        v-model="search"
        class="mb-4 mb-sm-15"
        @changedSearch="refresh"
      />
      
      <SelectOnPage
        class="pagination-container paginationColor--text ml-5 ml-sm-8 mb-4 mb-sm-15"
        :readonly="loading"
        @changeOnPage="refresh"
      />
    </div>
    
    <div class="destination-container">
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        :options="options"
        :custom-sort="() => items"
        class="elevation-0 borderNone tableBg rounded-20 borderTable"
        outlined
        disable-pagination
        hide-default-footer
        :loader-height="2"
        :header-props="{ sortIcon: '$sortDownIcon' }"
        :mobile-breakpoint="0"
        @update:options="changeOptions"
      >
        <template #no-data>
          <div class="d-flex">
            <span class="font-normal text-left">No data available</span>
          </div>
        </template>
        
        <template #item.name="{ item }">
          <div class="d-flex align-center">
            <span class="font-normal text-no-wrap">{{ item.name }}</span>
          </div>
        </template>
  
        <template #item.timezone="{ item }">
          <span class="font-normal text-no-wrap" v-if="item['timezone_id']">
            {{ getTimezoneNameById(item['timezone_id']) }}
          </span>
        </template>
        
        <template #item.status_id="{ item }">
          <SwitchSm
            :value="getStatus(item)"
            @input="changeStatus(item)"
            :loading="loadingStatus"
            bgColor="switcherYellow"
          />
        </template>
        
        <template #item.actions="{ item }">
          <TooltipAction bottom message="Edit">
            <template #activator>
              <v-icon class="mr-6" @click="openEditGroup(item)">$pencilIcon</v-icon>
            </template>
          </TooltipAction>
          
          <TooltipAction bottom message="Start attached destinations">
            <template #activator>
              <v-icon
                class="mr-6"
                :class="{ 'loading': loadingStatus }"
                @click="goToStartAttachedDestinations(item)"
              >$playIcon</v-icon>
            </template>
          </TooltipAction>
          
          <TooltipAction bottom message="Stop attached destinations">
            <template #activator>
              <v-icon
                class="mr-6"
                :class="{ 'loading': loadingStatus }"
                @click="goToPauseAttachedDestinations(item)"
              >$stopTableIcon</v-icon>
            </template>
          </TooltipAction>
          
          <TooltipAction bottom message="Reset caps">
            <template #activator>
              <v-icon class="mr-6" @click="openResetCapsDialog(item)">$resetIcon</v-icon>
            </template>
          </TooltipAction>
          
          <TooltipAction bottom message="Delete">
            <template #activator>
              <v-icon @click="openDeleteGroupDialog(item)">$deleteIcon</v-icon>
            </template>
          </TooltipAction>
        </template>
      </v-data-table>

      <TablePagination
        :loading="loading"
        :dataPagination="dataPagination"
        @input="changePage"
      />

      <div :class="dataPagination.lastPage > 1 ? 'pb-4' : 'pb-26'"></div>
    </div>
    
    <DialogConfirm
      v-model="confirmDialog"
      :loading="loadingAction"
      :title="dialogTitle"
      :subTitle="dialogSubTitle"
      :message="dialogMessage"
      @actionSuccess="goToDeleteGroup"
    />
    
    <DialogResetCaps
      v-model="resetCapsDialog"
      :itemId="groupSlugForResetCaps"
      forWhomAction="Group"
    />
  </v-container>
</template>

<script>
import ActionOutlinedButton from '@/components/buttons/ActionOutlinedButton'
import RefreshButton from '@/components/buttons/RefreshButton'
import ActionButton from '@/components/buttons/ActionButton'
import SearchField from '@/components/inputs/SearchField'
import InputNumberControl from '@/components/inputs/InputNumberControl'
import SelectOnPage from '@/components/select/SelectOnPage'
import TooltipAction from '@/components/tooltip/TooltipAction'
import SwitchSm from '@/components/controls/SwitchSm'
import DialogConfirm from '@/components/dialog/DialogConfirm'
import DialogResetCaps from '@/components/dialog/DialogResetCaps'
import TablePagination from '@/components/pagination/TablePagination'

import { mapGetters } from 'vuex'
import { Types as authTypes } from '@/store'
import { getGroups, deleteGroup, updateGroup, startDestinationsFromGroup, pauseDestinationsFromGroup } from '@/api/group-routes'
import { getOnPage, changeItemsAfterUpdate, parsePaginationData } from '@/helper/app-helper'

export default {
  name: 'ManageGroups',
  components: { ActionOutlinedButton, RefreshButton, ActionButton, SearchField, InputNumberControl, SelectOnPage, TooltipAction, SwitchSm, DialogConfirm, DialogResetCaps, TablePagination },
  data: () => ({
    loading: false,
    loadingAction: false,
    loadingStatus: false,
    items: [],
    dataPagination: {
      page: 1,
      lastPage: 1
    },
    options: {},
    headers: [
      { text: 'NAME', value: 'name', sortId: 'byName' },
      { text: 'TIMEZONE', value: 'timezone', sortId: 'byTimezone' },
      { text: 'STATUS', value: 'status_id', align: 'center', sortId: 'byStatus' },
      { text: 'ACTIONS', value: 'actions', sortable: false, width: 200, align: 'right' },
    ],
    confirmDialog: false,
    selectedItem: null,
    resetCapsDialog: false,
    groupSlugForResetCaps: null,
    dialogTitle: null,
    dialogSubTitle: null,
    dialogMessage: null,
    groupSlug: null,
    serverSorting: null,
    search: null,
  }),
  computed: {
    ...mapGetters({
      getTimezoneNameById: authTypes.getters.GET_TIMEZONE_NAME_BY_ID,
    }),
    checkMd() {
      return this.$vuetify.breakpoint.width > 1029
    },
  },
  methods: {
    changeOptions(options) {
      if (!this.loading) {
        this.options = Object.assign({}, options)
      }
    },
    async loadGroups() {
      this.loading = true

      const params = {
        page: this.dataPagination.page,
        onPage: getOnPage(),
        search: this.search,
        ...this.serverSorting,
      }

      const { success, payload, message } = await getGroups(params)

      if (success) {
        this.dataPagination = parsePaginationData(payload)
        this.items = payload.data
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loading = false
    },
    getStatus(item) {
      return item['status_id'] === 1
    },
    changePage(page) {
      if (page !== this.dataPagination.page) {
        this.dataPagination.page = page
        this.loadGroups()
      }
      this.$vuetify.goTo(0)
    },
    openEditGroup(group) {
      this.$router.push({ name: 'DetailGroup', params: { slug: group.slug }})
    },
    openResetCapsDialog(group) {
      this.resetCapsDialog = true
      this.groupSlugForResetCaps = group.slug
    },
    openDeleteGroupDialog(group) {
      this.selectedItem = group
      this.dialogTitle = 'Delete Group'
      this.dialogSubTitle = `${group.name}`
      this.dialogMessage = 'Are you sure you want to delete this Group?'
      this.confirmDialog = true
    },
    async goToStartAttachedDestinations(group) {
      this.loadingStatus = true
      
      const { success, message } = await startDestinationsFromGroup(group.slug)
      
      if (success) {
        this.$notify({ type: 'success-bg', text: message })
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
      
      this.loadingStatus = false
    },
    async goToPauseAttachedDestinations(group) {
      this.loadingStatus = true
      
      const { success, message } = await pauseDestinationsFromGroup(group.slug)
      
      if (success) {
        this.$notify({ type: 'success-bg', text: message })
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
      
      this.loadingStatus = false
    },
    async goToDeleteGroup() {
      this.loadingAction = true

      const { success, message } = await deleteGroup(this.selectedItem.slug)

      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.confirmDialog = false
        this.refreshDataAfterDelete()
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loadingAction = false
    },
    async changeStatus(group) {
      this.loadingStatus = true

      const params = {
        groupId: group.slug,
        formData: {
          status: group['status_id'] === 1 ? 2 : 1
        }
      }

      const { success, payload, message } = await updateGroup(params)

      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.items = changeItemsAfterUpdate(this.items, payload)
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loadingStatus = false
    },
    refreshDataAfterDelete() {
      if (this.items.length === 1 && this.dataPagination.page !== 1) {
        --this.dataPagination.page
      }

      this.loadGroups()
    },
    refresh() {
      this.dataPagination.page = 1
      this.loadGroups()
    },
  },
  watch: {
    options: {
      deep: true,
      handler(sortOptions) {
        if (sortOptions.sortBy.length) {
          const sortBy = this.headers.find(item => item.value === sortOptions.sortBy[0])
          this.serverSorting = { [sortBy.sortId]: sortOptions.sortDesc[0] ? 'asc' : 'desc' }
        } else {
          this.serverSorting = null
        }
        this.loadGroups()
      },
    },
  }
}
</script>

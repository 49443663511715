<template>
  <v-dialog
    v-bind="$attrs"
    v-model="show"
    max-width="510px"
    content-class="dialogConfirm"
    transition="scroll-y-transition"
    overlay-color="overlayColor"
  >
    <v-card class="cardBg px-10 py-25 px-sm-32 py-sm-32">
      <div class="d-flex flex-wrap align-center pb-4">
        <div class="font-large-title text-uppercase dialogConfirmTitle--text pr-11 pb-2">{{ title }}</div>
      </div>
      
      <div class="pb-18">
        <h3 class="font-normal dialogConfirmQuestion--text pb-4">{{ message }}</h3>
      </div>
      
      <div class="d-flex justify-end">
        <ActionButton class="mr-11" :loading="loadingAction" @click="makeAction">Yes, reset counters</ActionButton>
        
        <CancelButton @click="closeDialog">No</CancelButton>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import ActionButton from '@/components/buttons/ActionButton'
import CancelButton from '@/components/buttons/CancelButton'

import { resetDestinationCaps } from '@/api/destination-routes'
import { resetGroupCaps } from '@/api/group-routes'
import { resetBuyerCaps } from '@/api/buyer-routes'

export default {
  name: 'DialogResetCaps',
  inheritAttrs: false,
  components: { ActionButton, CancelButton },
  props: {
    value:  {
      type: Boolean,
      default: false
    },
    itemId: {
      type: String | Number,
      required: true
    },
    forWhomAction: {
      type: String,
      required: true,
      validator: value => ['Destination', 'Group', 'Buyer'].indexOf(value) !== -1
    },
  },
  data: () => ({
    loadingAction: false,
  }),
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    title() {
      return `Reset ${this.forWhomAction} Cap Counters`
    },
    message() {
      return `Resetting ${this.forWhomAction} Cap counters will reset all call counters related to this ${this.forWhomAction}. Including: All Time, Monthly, Weekly, Daily, etc. Are you sure you want to proceed?`
    },
  },
  methods: {
    async makeAction() {
      this.loadingAction = true
      
      const { success, message } = await this.callAction()
      
      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.$emit('actionSuccess')
        this.closeDialog()
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
      
      this.loadingAction = false
    },
    callAction() {
      if (this.forWhomAction === 'Destination') {
        return resetDestinationCaps(this.itemId)
      } else if (this.forWhomAction === 'Group') {
        return resetGroupCaps(this.itemId)
      } else if (this.forWhomAction === 'Buyer') {
        return resetBuyerCaps(this.itemId)
      }
    },
    closeDialog() {
      this.show = false
    },
  },
}
</script>
